<template>
  <button
    v-tooltip="{
      content: copiedMessage,
      triggers: ['click'],
    }"
    type="button"
    class="flex"
    @click="$copyText(content)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    content: { type: String, required: true },
    copiedMessage: { type: String, default: 'Copied!' },
  },
}
</script>
