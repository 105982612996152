<template>
  <button type="button" class="flex items-center gap-1" @click="toggleBookmark">
    <slot />
    <slot name="icon" :is-loading="isLoading" :bookmarked="bookmarked">
      <BookmarkIcon
        v-show="!isLoading"
        class="h-5 w-5"
        :class="{
          'text-primary': bookmarked,
          'text-gray-400': !bookmarked,
        }"
      />
      <LoadingCircle v-show="isLoading" class="h-5 w-5 animate-spin text-primary" />
    </slot>
    <slot v-if="showCount" name="count">
      <span class="text-sm">
        {{ bookmarkable.bookmarks_count || 0 }}
      </span>
    </slot>
    <slot name="after" />
  </button>
</template>

<script>
import { mapState } from 'pinia'
import BookmarkIcon from '~/components/icons/BookmarkIcon'
import LoadingCircle from '~/components/icons/LoadingCircle'
import { useAuthStore } from '~/stores/auth'
import { useApi } from '~/composables/useApi'

export default {
  components: { BookmarkIcon, LoadingCircle },

  props: {
    bookmarkable: { type: Object, required: true },
    type: { type: String, required: true },
    showCount: { type: Boolean, default: true },
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    ...mapState(useAuthStore, ['user']),

    signedIn () {
      return !! this.user
    },

    bookmarked () {
      return this.bookmarkable.bookmarked
    },
  },

  methods: {
    async bookmark () {
      await useApi('/api/bookmarks', {
        method: 'post',
        body: {
          bookmarkable_id: this.bookmarkable.id,
          bookmarkable_type: this.type,
        },
      })

      // eslint-disable-next-line vue/no-mutating-props
      this.bookmarkable.bookmarked = true
      // eslint-disable-next-line vue/no-mutating-props
      this.bookmarkable.bookmarks_count = this.bookmarkable.bookmarks_count + 1
    },

    async unbookmark () {
      await useApi('/api/bookmarks', {
        method: 'post',
        body: {
          bookmarkable_id: this.bookmarkable.id,
          bookmarkable_type: this.type,
        },
      })
      // eslint-disable-next-line vue/no-mutating-props
      this.bookmarkable.bookmarked = false
      // eslint-disable-next-line vue/no-mutating-props
      this.bookmarkable.bookmarks_count = this.bookmarkable.bookmarks_count - 1
    },

    async toggleBookmark () {
      if (! this.signedIn) {
        window.location.href = '/users/sign-in'
      } else if (! this.isLoading) {
        this.isLoading = true

        if (this.bookmarkable.bookmarked) {
          await this.unbookmark()
        } else {
          await this.bookmark()
        }
        this.isLoading = false
      }
    },
  },
}
</script>
